import React from 'react';
import styled from 'styled-components';

import { StaticQuery } from 'gatsby';

import Title from './shared/Title';
import Anchor from './shared/Anchor';
import Paragraph from './shared/Paragraph';

const Pill = styled.span`
  padding: 3px 11px;
  margin-right: 8px;
  background: #adadad;
  color: ${props => props.theme.modeColors.background};
  border-radius: 500px;
`;

export default () => (
  <StaticQuery
    query={graphql`
      query DevPosts {
        allDevArticles {
          edges {
            node {
              article {
                id
                published_at
                title
                description
                url
                tags
              }
            }
          }
        }
      }
    `}
    render={({ allDevArticles: { edges } }) => {
      return (
        <>
          {edges.map(({ node }) => {
            return (
              <div key={node.article.slug}>
                <Title>
                  <Anchor href={node.article.url}>{node.article.title}</Anchor>
                </Title>
                <Paragraph>
                  <small>
                    {new Intl.DateTimeFormat('en', {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    }).format(new Date(node.article.published_at))}
                  </small>
                </Paragraph>
                <Paragraph>{node.article.description}</Paragraph>
                <Paragraph>
                  {node.article.tags.map(tag => (
                    <Pill key={tag}>{tag}</Pill>
                  ))}
                </Paragraph>
              </div>
            );
          })}
        </>
      );
    }}
  />
);
