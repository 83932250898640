import React from 'react';

import SEO from '../components/SEO';
import BlogList from '../components/BlogList';

export default () => (
  <>
    <SEO title="Blog" />
    <BlogList />
  </>
);
